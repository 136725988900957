import Link from 'next/link';
import { useRouter } from 'next/router';
import { FeatureAccessor } from '../featureAccessor';
import { Account } from './account';
import { BackToMenu } from './back';
import { Basket } from './basket';
import * as Styled from './styles';
import { AndroidBackButton } from '@/components/android/back-button';
import AndroidNavigation from '@/components/android/navigation-drawer';
import { styled } from '@/components/content/stitches';
import { GenericRouteAccessor, getAllowedRoutes } from '@/components/genericRouteAccessor';
import { Navigation } from '@/components/header/navigation-drawer';
import { Box, Stack } from '@/components/ui';
import { Render } from '@/components/uielements/render';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import { FEATURE_FLAGS } from '@/constants/features';
import { ROUTES } from '@/constants/routes';
import { MenuAvailability } from '@/features/menu-availability/menu-availability';
import { useSelector } from '@/redux';
import { useConfigOverride } from '@/redux/cmsConfig';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { isAndroidShell } from '@/utils/android';
import { resizeImage } from '@/utils/imageHelper';
import { parseCustomUserAgent, getMobileStyles } from '@/utils/mobile';
import { isBrandDomain } from '@/utils/urls';

export function Header() {
  const {
    background_color,
    background_image,
    border_color,
    font,
    logo,
    navigation_display,
    logo_cta_url,
    mobile_logo_width,
    desktop_logo_width,
  } = useConfigOverride('header');

  const router = useRouter();

  const me = useSelector((state) => state.app.me.data);
  const { organization } = useSelector((state) => state.app.organization);
  const webConfig = useSelector((state) => state.app.cmsConfig.webConfig);

  const logoImg = logo ? (
    <Logo
      css={{
        maxWidth: mobile_logo_width ? `${mobile_logo_width}px` : '$40',

        '@md': {
          maxWidth: desktop_logo_width ? `${desktop_logo_width}px` : '$48',
        },
      }}
      src={resizeImage(logo, { width: 300 })}
      alt={organization.label}
    />
  ) : (
    <Styled.OrganizationLabel theme={webConfig}>{organization.label}</Styled.OrganizationLabel>
  );

  const onAndroidSplashScreen = router.pathname.includes(ROUTES.ANDROID);
  const hasNavigation = Boolean(navigation_display !== 'hidden');
  const inlineMenu = Boolean(navigation_display === 'link list');

  const showMenuFiltering = getAllowedRoutes(router.pathname, [
    ROUTES.STORE,
    ROUTES.LOGIN,
    ROUTES.SIGN_UP,
  ]);

  const isCOOMobile_iOS = parseCustomUserAgent()?.mobile_ios;

  const getLogoRoute = () => {
    // if we're using a vanity domain, redirect to the brand's marketing site
    if (isBrandDomain()) {
      return `https://${window.location.hostname}`;
    }
    // return the logo_cta_url if it exists, otherwise default to the stores page
    return !!logo_cta_url ? logo_cta_url : ROUTES.STORES;
  };

  return (
    <>
      {isAndroidShell() && onAndroidSplashScreen && <AndroidNavigation />}

      {!onAndroidSplashScreen && (
        <Container
          data-css-override="StyledHeader"
          className={`${CSS_CLASSES.GLOBAL_HEADER.CONTAINER} ${
            isCOOMobile_iOS && { height: 'auto' }
          }`}
          css={{
            backgroundColor: background_color ?? 'white',
            backgroundImage: background_image ? `url(${background_image})` : 'none',
            borderBottom: `1px solid ${border_color ?? 'transparent'}`,
            ...getFontStyles(font),
            ...(isCOOMobile_iOS && {
              ...getMobileStyles(['paddingTop']),
              height: 'auto',
              paddingBottom: '12px',
            }),
            '@xl': {
              marginBottom: 0,
            },
          }}
          showMenuFiltering={showMenuFiltering}
        >
          {isAndroidShell() ? (
            <AndroidBackButton />
          ) : (
            <>
              <Box
                css={{
                  alignItems: 'center',
                  display: 'grid',
                  gridTemplateColumns: '$16 1fr $16',
                  gridTemplateRows: '1fr',

                  '@md': {
                    display: 'flex',
                    overflow: 'hidden',
                    gap: '$10',
                  },
                }}
              >
                <Navigation
                  hasNavigation={hasNavigation}
                  inlineMenu={inlineMenu}
                  signedIn={Boolean(me?.id)}
                />

                <div>
                  <FeatureAccessor
                    featureFlag={FEATURE_FLAGS.GLOBAL__LOCATIONS_PAGE}
                    renderFallback={logoImg}
                  >
                    <Link href={getLogoRoute()} passHref={true} legacyBehavior>
                      <LogoLink
                        data-testid="nav-logo-link"
                        id="nav-logo"
                        target={Boolean(logo_cta_url) ? '_blank' : '_self'}
                        css={{
                          order: inlineMenu ? 1 : 2,
                        }}
                      >
                        {logoImg}
                      </LogoLink>
                    </Link>
                  </FeatureAccessor>
                </div>

                {/* Do not show this on the checkout page */}
                {router.pathname !== ROUTES.CHECKOUT &&
                  router.pathname !== ROUTES.ORDER_CONFIRMATION &&
                  router.pathname !== ROUTES.ARRIVAL_CONFIRMATION && <Basket showOnMobile={true} />}

                {!hasNavigation && (
                  <GenericRouteAccessor
                    allowedPages={{
                      [ROUTES.ACCOUNT]: null,
                      [ROUTES.CHECKOUT]: { exactMatch: true },
                      [ROUTES.LOGIN]: { exactMatch: true },
                      [ROUTES.SIGN_UP]: { exactMatch: true },
                    }}
                    component={
                      <Box
                        css={{
                          display: 'none',
                          order: 3,

                          '@md': {
                            display: 'block',
                          },
                        }}
                      >
                        <BackToMenu />
                      </Box>
                    }
                  />
                )}
              </Box>

              <Render condition={showMenuFiltering}>
                <Stack
                  css={{
                    backgroundColor: background_color ?? 'white',
                    borderBottom: `1px solid ${border_color ?? 'transparent'}`,
                    height: '$14',
                    left: 0,
                    padding: '0 $4',
                    position: 'absolute',
                    top: 'calc(100% + 1px)',
                    width: '100%',

                    '@md': {
                      padding: '0 $10',
                    },

                    '@xl': {
                      backgroundColor: 'revert',
                      border: 'revert',
                      padding: '0 $2',
                      position: 'static',
                      width: 'revert',
                    },
                  }}
                  position="center"
                >
                  <MenuAvailability />
                </Stack>
              </Render>

              <Stack
                css={{
                  display: 'none',
                  gap: '$10',

                  '@md': {
                    display: 'flex',
                  },
                }}
              >
                <Box
                  css={{
                    '@md': {
                      marginLeft: 'auto',
                    },
                  }}
                >
                  <Account />
                </Box>

                {/* Do not show this on the checkout page */}
                {router.pathname !== ROUTES.CHECKOUT &&
                  router.pathname !== ROUTES.ORDER_CONFIRMATION &&
                  router.pathname !== ROUTES.ARRIVAL_CONFIRMATION && (
                    <Basket showOnMobile={false} />
                  )}
              </Stack>
            </>
          )}
        </Container>
      )}
    </>
  );
}

const Container = styled('header', {
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '1fr',
  height: '$12',
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 11,

  '@md': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(min-content, 1fr))',
    height: '$20',
    padding: '0 $10',
  },

  variants: {
    showMenuFiltering: {
      false: {
        '@xl': {
          gridTemplateColumns: '1fr 1fr',
        },
      },
      true: {
        marginBottom: '$14', // used to push other content out of the way for menu availability

        '@xl': {
          gridTemplateColumns: '18rem 1fr 18rem',
          marginBottom: 0,
        },
      },
    },
  },
  defaultVariants: {
    showMenuFiltering: false,
  },
});

const LogoLink = styled('a', {
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',

  '@md': {
    justifyContent: 'flex-start',
  },
});

const Logo = styled('img', {
  display: 'block',
  height: '$10',
  objectFit: 'contain',
  objectPosition: 'center',

  '@md': {
    height: '$14',
    objectPosition: 'left',
  },
});
