import { type LoyaltyUser } from '@koala/sdk';
import { type ConnectedProps, connect } from 'react-redux';
import { ConnectedChevronIcon, ConnectedPersonIcon } from '../uielements';
import {
  StyledFirstName,
  StyledHeaderAccountButton,
  StyledNotificationsCounter,
  StyledRewardsNotifications,
  StyledRewardNumber,
  StyledAwardIcon,
} from './styles';
import { CSS_CLASSES } from '@/constants/cssClassNames';
import meActions from '@/redux/me/actions';
import { type RootState } from '@/types/app';
import { getActiveRewardsAndOffersLength, getUnreadPersonalMessagesLength } from '@/utils/loyalty';

interface Props extends ReduxProps {
  me: LoyaltyUser | null;
}

const LoyaltyNavLink = ({
  toggleAccountDropdown,
  myMessages,
  myRedeemables,
  myOffers,
  me,
  loyaltyState,
}: Props) => {
  // Count unread personal messages + offers + rewards
  const currentPoints = loyaltyState?.points ?? 0;
  const combinedRewardsLength =
    getActiveRewardsAndOffersLength(myRedeemables, myOffers, currentPoints) || 0;
  const totalNotifications = getUnreadPersonalMessagesLength(myMessages) + combinedRewardsLength;

  return (
    <StyledHeaderAccountButton
      aria-label="Click to view more Account links"
      onClick={() => toggleAccountDropdown(true)}
    >
      <StyledNotificationsCounter
        data-css-override="StyledNotificationsCounter"
        className={CSS_CLASSES.GLOBAL_HEADER.AUTH_NOTIFICATION_COUNTER}
      >
        <ConnectedPersonIcon />

        {/* Notification counter - rewards and unread messages */}
        <StyledRewardsNotifications>
          <StyledAwardIcon src="/static/img/icons/award.svg" alt="rewards" />
          <StyledRewardNumber>{totalNotifications}</StyledRewardNumber> Rewards
        </StyledRewardsNotifications>
      </StyledNotificationsCounter>
      <StyledFirstName className={CSS_CLASSES.GLOBAL_HEADER.AUTH_USERNAME}>
        Hi, {me?.first_name}
      </StyledFirstName>
      <ConnectedChevronIcon />
    </StyledHeaderAccountButton>
  );
};

const mapStateToProps = (state: RootState) => ({
  accountDropdownOpen: state.app.me.accountDropdownOpen,
  myOffers: state.app.me.myOffers,
  myRedeemables: state.app.me.redeemables,
  myMessages: state.app.me.messages,
  loyaltyState: state.app.me.loyaltyState,
});

const mapDispatchToProps = {
  toggleAccountDropdown: meActions.toggleAccountDropdown,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(LoyaltyNavLink);
