import { CONVEYANCE_TYPES } from '@koala/sdk/v4';
import { type Location } from '@koala/sdk';
import * as Popover from '@radix-ui/react-popover';
import { readableColor } from 'polished';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { utcToZonedTime } from 'date-fns-tz';
// import { ConveyanceDetails } from './conveyance-details';
import { DeliveryIcon } from './icons/delivery';
import { PickupIcon } from './icons/pickup';
import { LocationDetails } from './location-details';
import { TimePicker } from './time-picker';
import { DeliveryDetails } from './delivery-details';
import { Box, Stack } from '@/components/ui';
import { DATE_FORMAT } from '@/constants/dates';
import { API_CONVEYANCE_TYPES } from '@/constants/events';
import { useLocation } from '@/features/locations/service';
import { useDispatch, useSelector } from '@/redux';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import { formatDate, isDaySame } from '@/utils/dates';
import Icon from '@/components/uielements/icon';
import { getStoreTimeInfo } from '@/utils/locations';
import { safePolished } from '@/utils/safePolished';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { Render } from '@/components/uielements/render';

export function getFulfillmentTime(time: string | undefined, location: Location | undefined) {
  const { storeTimezone, storeDateTimeNow } = getStoreTimeInfo(location);

  if (!time || time === 'asap' || !location) {
    return `Today`;
  }

  // Convert the input time to the store's timezone using the offset
  const fulfillmentTimeInStoreZone = utcToZonedTime(new Date(time), storeTimezone);

  if (isDaySame(storeDateTimeNow, fulfillmentTimeInStoreZone)) {
    return `Today, ${formatDate(fulfillmentTimeInStoreZone, DATE_FORMAT.HOURS_WITH_MINUTES)}`;
  }

  if (!isDaySame(storeDateTimeNow, fulfillmentTimeInStoreZone)) {
    return `${formatDate(fulfillmentTimeInStoreZone, DATE_FORMAT.WEEKDAY_HOURS_MINUTES)}`;
  }

  return formatDate(fulfillmentTimeInStoreZone, DATE_FORMAT.HOURS_WITH_MINUTES);
}

export function Conveyance() {
  const [popoverVisible, setPopoverVisibility] = useState(false);
  const { type, address, time_wanted, location } = useSelector(selectConveyance);
  const { data: storeLocation, isSuccess } = useLocation(location);
  const [activeConveyance, setActiveConveyance] = useState(type || CONVEYANCE_TYPES.PICKUP);

  const dispatch = useDispatch();

  const clearAndSetDeliveryAddress = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>) => {
      if (e) e.stopPropagation();
      if (storeLocation) {
        setActiveConveyance(CONVEYANCE_TYPES.PICKUP);

        dispatch(
          conveyanceModeActions.conveyanceModeSet(
            {
              type: API_CONVEYANCE_TYPES.PICKUP,
              details: [],
            },
            time_wanted,
          ),
        );
      }
    },
    [storeLocation, time_wanted, dispatch],
  );
  /*
   * Setting delivery conveyance without adding an address can cause
   * a visual bug where the handoff UI shows a loading state
   *
   * This happens both when closing the popover and refreshing (both without
   * setting an address). We check for this unique pattern with a `useEffect`
   * that resets the conveyance mode if we find ourselves in this state.
   */
  useEffect(() => {
    if (
      !popoverVisible &&
      type === CONVEYANCE_TYPES.DELIVERY &&
      !address?.street_address &&
      isSuccess
    ) {
      clearAndSetDeliveryAddress();
    }
  });

  useEffect(() => {
    if (!popoverVisible && !address?.street_address) {
      setActiveConveyance(CONVEYANCE_TYPES.PICKUP);
    }
  }, [popoverVisible, address]);

  useEffect(() => {
    setActiveConveyance(type);
  }, [type]);

  return (
    <Popover.Root
      open={popoverVisible}
      onOpenChange={(open) => {
        setPopoverVisibility(open);
      }}
    >
      <Trigger>
        <Stack direction="horizontal" gap="0">
          <Render condition={!!storeLocation && storeLocation?.data?.supports_delivery}>
            <StyledButton
              $active={activeConveyance === CONVEYANCE_TYPES.DELIVERY}
              $removeLeftBorder={false}
              $removeRightBorder={storeLocation?.data?.supports_pick_up}
              onClick={(e) => {
                e.stopPropagation(); // Prevent default toggle
                setActiveConveyance(CONVEYANCE_TYPES.DELIVERY);
                setPopoverVisibility(true);
              }}
            >
              <DeliveryIcon />
              Delivery
            </StyledButton>
          </Render>
          <Render condition={!!storeLocation && storeLocation?.data?.supports_pick_up}>
            <StyledButton
              $active={activeConveyance === CONVEYANCE_TYPES.PICKUP}
              $removeLeftBorder={storeLocation?.data?.supports_delivery}
              $removeRightBorder={false}
              onClick={(e) => {
                clearAndSetDeliveryAddress(e);
                setPopoverVisibility(true);
              }}
            >
              <PickupIcon />
              Pickup
            </StyledButton>
          </Render>

          <FulfillmentTime>{getFulfillmentTime(time_wanted, storeLocation?.data)}</FulfillmentTime>
        </Stack>
      </Trigger>

      <Popover.Portal>
        <Content sideOffset={5}>
          <Section>
            <Popover.Close asChild>
              <Icon.Close
                title="close icon"
                style={{ cursor: 'pointer' }}
                onClick={() => setPopoverVisibility(!popoverVisible)}
              />
            </Popover.Close>
            <Box css={{ width: '100%' }}>
              {activeConveyance === CONVEYANCE_TYPES.DELIVERY ? (
                <DeliveryDetails />
              ) : (
                <LocationDetails />
              )}
            </Box>
          </Section>
          {/**
           * Non-pickup locations show the store details below the delivery address
           * form to ensure proper context for where you are ordering from.
           */}
          {!Boolean(storeLocation?.data?.supports_pick_up) && (
            <>
              <Divider />
              <Section>
                <LocationDetails />
              </Section>
            </>
          )}

          <Divider />
          <Section>
            <TimePicker />
          </Section>
          <Arrow
            style={{
              marginLeft:
                storeLocation?.data?.supports_delivery && storeLocation?.data?.supports_pick_up
                  ? activeConveyance === CONVEYANCE_TYPES.DELIVERY
                    ? '210px'
                    : '20px'
                  : '115px', // When only one button is displayed
            }}
          />
        </Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

const StyledButton = styled.button<{
  $active: boolean;
  $removeRightBorder?: boolean;
  $removeLeftBorder?: boolean;
}>(({ theme, $active, $removeRightBorder, $removeLeftBorder }) => ({
  all: 'unset',
  boxSizing: 'border-box',
  alignItems: 'center',
  backgroundColor: $active ? theme.buttons.primary_button_background_color : 'transparent',
  border: '1px solid',
  borderColor: theme.header.border_color ?? '#ddd',
  borderRadius: (() => {
    if ($removeLeftBorder && !$removeRightBorder) return '0 8px 8px 0';
    if (!$removeLeftBorder && $removeRightBorder) return '8px 0 0 8px';
    if ($removeLeftBorder && $removeRightBorder) return '0';
    return '8px';
  })(),
  borderLeft: $removeLeftBorder ? 'none' : `1px solid ${theme.header.border_color ?? '#ddd'}`,
  borderRight: $removeRightBorder ? 'none' : `1px solid ${theme.header.border_color ?? '#ddd'}`,
  cursor: 'pointer',
  display: 'flex',
  gap: '4px',
  height: '32px',
  padding: '8px',
  ...getFontStyles(
    theme.buttons.primary_font,
    ['color', 'font_family', 'font_weight', 'line_height'],
    {
      color: (value) => {
        if ($active) {
          if (value === 'revert' || value === null) {
            return safePolished(readableColor)(theme.buttons.primary_button_background_color);
          }
          return value;
        }
        return theme.modals.font.color;
      },
    },
  ),
  '@media (max-width: 375px)': {
    height: '26px',
    fontSize: '12px',
  },
}));

const FulfillmentTime = styled.span({
  whiteSpace: 'nowrap',
  paddingLeft: '6px',
  '@media (max-width: 375px)': {
    fontSize: '12px',
  },
});

const Trigger = styled(Popover.Trigger)({
  all: 'unset',

  ':focus-visible': {
    outline: 'revert',
  },
});

const Content = styled(Popover.Content)(({ theme }) => ({
  animationName: 'slideUpAndFade',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  background: theme.modals.background_color ?? 'white',
  borderRadius: '.5rem',
  filter: 'drop-shadow(0px -1px 9px rgba(0,0,0,0.05))',
  transformOrigin: 'var(--radix-popover-content-transform-origin)',
  willChange: 'transform, opacity',
  width: '100vw',
  zIndex: 100,

  '@media (min-width: 640px)': {
    width: '24rem',
  },

  '@keyframes slideUpAndFade': {
    '0%': { opacity: 0, transform: 'translateY(0.25rem)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
  },

  ':focus': {
    outline: 'none',
  },
}));

const Section = styled.div({
  padding: '1.5rem',
});

const Divider = styled.hr(({ theme }) => ({
  background: theme.global.primary_border_color ?? '#e5e5e5',
  border: 'none',
  height: '1px',
  margin: 0,
}));

const Arrow = styled(Popover.Arrow)({
  visibility: 'hidden',
  fill: 'white',
  height: '9px',
  width: '18px',

  '@media (min-width: 640px)': {
    visibility: 'visible',
  },
});
