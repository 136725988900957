import Link from 'next/link';
import { useEffect, useState } from 'react';
import { OrderNowButton } from '../home/navigation/navigation.styles';
import * as styled from './LostConnection.styles';
import { ROUTES } from '@/constants/routes';

const LostConnectionSlider = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleOffline = () => setIsOnline(false);
    const handleOnline = () => {
      setIsOnline(true);
      window.location.reload();
    };

    setIsOnline(window.navigator.onLine);

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  return !isOnline ? (
    <>
      <styled.BlurOverlay />
      <styled.Section>
        <styled.Content>
          <div>
            <h1>Whoops</h1>
          </div>
          <div>
            <p>No network connection detected. Check your connection and try again.</p>
          </div>
          <Link href={ROUTES.STORES} legacyBehavior>
            <OrderNowButton onClick={() => setIsOnline(window.navigator.onLine)}>OK</OrderNowButton>
          </Link>
        </styled.Content>
      </styled.Section>
    </>
  ) : null;
};

export default LostConnectionSlider;
