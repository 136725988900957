import { type DeliveryAddress } from '@koala/sdk';
import loadGoogleMapsApi from 'load-google-maps-api';
import { nanoid } from 'nanoid';
import { useState, useEffect } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import { autocompleteInputId } from './autocomplete-input-id';
import { StyledAutocompleteInstructions, StyledInputResetButton } from './styles';
import { addressValidation } from './validation';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { SecureField } from '@/components/uielements/form-fields';
import { StyledLabel } from '@/components/uielements/label';
import { StyledSearchInput } from '@/components/uielements/searchInput';
import { K_ANALYTICS_EVENTS, LOG_EVENTS } from '@/constants/events';
import { DELIVERY_TIME_WANTED_MODES, GOOGLE_LIBRARIES } from '@/constants/global';
import { SUPPORTED_COUNTRIES } from '@/constants/locations';
import { StyledLoader } from '@/features/handoff/time-picker/styles';
import { locationsActions } from '@/redux/locations/actions';
import { type RootState } from '@/types/app';
import * as ErrorReporter from '@/utils/errorReporter';
import { fireKAnalyticsEvent } from '@/utils/koalaAnalytics';
import { safelyGetString } from '@/utils/stringHelpers';
interface Props extends ReduxProps {
  apiKey: string;
  loading: boolean;
  checkOrSearchDeliveryCoverage: (address: DeliveryAddress) => void;
  formValues?: Record<string, string>;
}

const GoogleAutocomplete = (props: Props) => {
  // We need to generate unique input id for each GoogleAutocomplete component
  // as there are cases where we could have more than one instance of GoogleAutocomplete component:
  // Delivery Search on Locators page and Opened Delivery Form Modal
  const [generatedInputId] = useState<string>(`${autocompleteInputId}-${nanoid()}`);
  const [address, setAddress] = useState<DeliveryAddress | null>(null);
  const [, setSearchValue] = useState<string>('');
  const [missingAddressFields, setMissingAddressFields] = useState<string[]>([]);

  useEffect(() => {
    // Clear any previous errors
    props.clearDeliverySearchErrors();

    // If Google Maps has been loaded with Autocomplete, initialize Autocomplete
    if (window?.google?.maps?.places?.Autocomplete !== undefined) {
      initAutocomplete();
      return;
    }
    // Otherwise load Google Maps and with the Autocomplete library
    const loadGoogleMap = async () => {
      try {
        await loadGoogleMapsApi({
          key: props.apiKey,
          libraries: [GOOGLE_LIBRARIES.PLACES],
        });
        initAutocomplete();
      } catch (error) {
        ErrorReporter.captureException(error);
        console.error(error);
      }
    };
    void loadGoogleMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let autocomplete: google.maps.places.Autocomplete | null = null;

  const initAutocomplete = () => {
    if (autocomplete) {
      // Session already started
      return;
    }

    const input = document.getElementById(generatedInputId) as HTMLInputElement;

    autocomplete = new window.google.maps.places.Autocomplete(input, {
      types: ['geocode'],
      fields: ['address_components', 'place_id', 'name', 'types'],
      componentRestrictions: {
        country: Object.values(SUPPORTED_COUNTRIES),
      },
    });
    autocomplete.addListener('place_changed', storeSelectedAddress);

    // This disables 'enter' form submissions when the autocomplete input is in focus
    input.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
  };

  const mapAutocompleteAddress = (selectedLocation?: google.maps.places.PlaceResult) => {
    const addressComponents = selectedLocation?.address_components;

    if (!addressComponents) {
      // Throw error if address is not recognized by Google
      props.showGoogleAddressNotSelectedError();
      return;
    }

    // Mapping of GeocoderAddressComponent.types[] to GoogleAddressComponentFields
    // All types represented as string and can be found
    // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
    const newFormattedAddress: Record<string, string> = {};

    addressComponents.map(
      (component) => (newFormattedAddress[component.types[0]] = component.short_name),
    );

    return newFormattedAddress;
  };

  // Set the selection to the Google autocomplete provided address
  const storeSelectedAddress = () => {
    const { clearDeliverySearchErrors, showGoogleAddressNotSelectedError, formValues } = props;

    // Clear any previous errors
    clearDeliverySearchErrors();

    const selectedLocation = autocomplete?.getPlace();
    const addressComponents = mapAutocompleteAddress(selectedLocation);

    if (!addressComponents) {
      // Throw error if address is not recognized by Google
      showGoogleAddressNotSelectedError();
      return;
    }

    // Parse street address
    const { street_number, route } = addressComponents;
    const streetAddress = `${street_number ?? ''} ${route ?? ''}`.trim();

    // Set address in the format we want on state
    setAddress({
      time_wanted: formValues?.time_wanted ?? DELIVERY_TIME_WANTED_MODES.ASAP,
      time_wanted_mode: formValues?.time_wanted
        ? DELIVERY_TIME_WANTED_MODES.ADVANCE
        : DELIVERY_TIME_WANTED_MODES.ASAP,
      street_address: streetAddress,
      city:
        addressComponents.locality ??
        addressComponents.sublocality ??
        addressComponents.sublocality_level_1 ??
        addressComponents.administrative_area_level_3 ??
        '',
      state: addressComponents.administrative_area_level_1,
      zip_code: addressComponents.postal_code ?? '',
    });
  };

  useEffect(() => {
    if (address) {
      handleSubmitAddress(address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const handleSubmitAddress = (address: DeliveryAddress) => {
    const { showDeliveryAddressInvalidError, checkOrSearchDeliveryCoverage } = props;

    const validation = address && addressValidation(address);

    // Throw error if missing required address fields
    if (Object.keys(validation).length > 0) {
      setMissingAddressFields(Object.keys(validation));
      showDeliveryAddressInvalidError();
      return;
    }

    // This has been extended to accept both API calls, depending on where it's used
    checkOrSearchDeliveryCoverage(address);
  };

  const clearInputField = () => {
    const element = document.getElementById(generatedInputId) as HTMLInputElement;
    element.value = '';
    props.clearDeliverySearchErrors();
    setSearchValue('');
  };

  const renderInputError = () => {
    const { invalidDeliverySearchAddress, googleAddressNotSelected } = props;
    const formattedMissingAddressFields = missingAddressFields.join(', ').replace(/_/g, ' ');

    // User enters incomplete address, e.g. Brooklyn, NY
    if (invalidDeliverySearchAddress) {
      return (
        missingAddressFields.length > 0 && (
          <StringAccessor
            accessor="locations.incomplete_address_text"
            html={true}
            dataObj={{ missingAddressFields: formattedMissingAddressFields }}
          />
        )
      );
    }

    if (googleAddressNotSelected) {
      // KA event
      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOG, {
        name: LOG_EVENTS.GOOGLE_ADDRESS_NOT_SELECTED,
        details: 'Delivery Search',
      });

      return (
        // User presses enter without selecting an address from the dropdown
        <StringAccessor accessor="locations.google_address_not_selected_text" html={true} />
      );
    }
  };

  const {
    strings,
    loading,
    invalidDeliverySearchAddress,
    noDeliveryLocationsFound,
    googleAddressNotSelected,
  } = props;

  const inputId = 'delivery-address-autocomplete-instructions';

  return (
    <>
      <div>
        <StyledLabel as="span" id="autocomplete-instructions" $visuallyHidden={true}>
          Start typing your address, key down when you find your address, and then press enter
        </StyledLabel>
        <SecureField
          fieldType={inputId}
          label="Delivery Address"
          error={renderInputError()}
          aria-describedby={inputId}
        >
          <StyledSearchInput
            id={generatedInputId}
            placeholder={safelyGetString(strings, 'locations.delivery_search_placeholder')}
            $padding="0 25px 0 10px"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          />
          {loading && !noDeliveryLocationsFound && <StyledLoader $right={20} $top={10} />}

          {(noDeliveryLocationsFound ||
            invalidDeliverySearchAddress ||
            googleAddressNotSelected) && (
            <StyledInputResetButton aria-label="Clear address" onClick={clearInputField}>
              &times;
            </StyledInputResetButton>
          )}
        </SecureField>
      </div>

      {/* No Delivery locations returned from API  */}
      {noDeliveryLocationsFound && !invalidDeliverySearchAddress && !loading && (
        <StringAccessor
          tag={StyledAutocompleteInstructions}
          accessor="locations.delivery_search_no_results_text"
          html={true}
        />
      )}

      {/* Delivery Search Instructional Text */}
      {!noDeliveryLocationsFound && !invalidDeliverySearchAddress && !googleAddressNotSelected && (
        <StringAccessor
          tag={StyledAutocompleteInstructions}
          accessor="locations.delivery_search_instructional_text"
          html={true}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  strings: state.app.cmsConfig.strings,
  invalidDeliverySearchAddress: state.app.locations.invalidDeliverySearchAddress,
  noDeliveryLocationsFound: state.app.locations.noDeliveryLocationsFound,
  googleAddressNotSelected: state.app.locations.googleAddressNotSelected,
});

const mapDispatchToProps = {
  showDeliveryAddressInvalidError: locationsActions.showDeliveryAddressInvalidError,
  showGoogleAddressNotSelectedError: locationsActions.showGoogleAddressNotSelectedError,
  clearDeliverySearchErrors: locationsActions.clearDeliverySearchErrors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(GoogleAutocomplete);
