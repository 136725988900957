import { type ConnectedProps, connect } from 'react-redux';
import {
  StyledForceDismissButton,
  StyledToastLayout,
  ToastContent,
  StyledSecondaryButton,
} from './styles';
import { GenericFocusTrap } from '@/components/uielements/genericFocusTrap';
import globalActions from '@/redux/global/actions';
import { type RootState } from '@/types/app';

let timeout: ReturnType<typeof setTimeout>;
const timedDismiss = (display: boolean, dismissToast: () => void, forceDismiss?: boolean) => {
  if (forceDismiss) {
    return;
  }
  if (display) {
    timeout = setTimeout(dismissToast, 4000);
  }
};

const Toast = ({ toast, dismissToast }: ReduxProps) => {
  if (!toast.display) {
    return null;
  }

  return (
    <GenericFocusTrap
      focusTrapOptions={{
        clickOutsideDeactivates: true,
        initialFocus: '#Global-Toast',
      }}
    >
      <StyledToastLayout
        data-css-override="StyledToastLayout"
        $status={toast.status}
        id="Global-Toast"
      >
        {timedDismiss(toast.display, dismissToast, toast.forceDismiss)}
        <ToastContent>
          <p
            aria-live="polite"
            onFocus={() => clearTimeout(timeout)}
            dangerouslySetInnerHTML={{ __html: toast.message }}
          />
          <StyledSecondaryButton
            onClick={() => window.location.reload()}
            aria-label="Click to refresh the page"
            style={{ marginTop: '1rem' }}
          >
            Refresh Page
          </StyledSecondaryButton>
        </ToastContent>

        <StyledForceDismissButton onClick={dismissToast} aria-label="Click to close alert">
          &times;
        </StyledForceDismissButton>
      </StyledToastLayout>
    </GenericFocusTrap>
  );
};

const mapStateToProps = (state: RootState) => ({
  toast: state.app.global.toast,
});

const mapDispatchToProps = {
  dismissToast: globalActions.dismissToast,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(Toast);
