import Link from 'next/link';
import { useRouter } from 'next/router';
import { styled } from '@/components/content/stitches';
import { Image } from '@/components/image';
import { Stack } from '@/components/ui';
import { Render } from '@/components/uielements/render';
import { Social } from '@/components/uielements/social';
import { SocialLinks } from '@/components/uielements/social/types';
import { DATE_FORMAT } from '@/constants/dates';
import { ROUTES } from '@/constants/routes';
import { useSelector } from '@/redux';
import { useConfigOverride } from '@/redux/cmsConfig';
import { type FooterLink, useFooterQuery } from '@/services/footer.service';
import { __DANGEROUS_EMPTY_REQUEST_ORIGIN, getOrigin } from '@/utils';
import { formatDate } from '@/utils/dates';
import { resizeImage } from '@/utils/imageHelper';
import { interpolateIntoString, safelyGetConfig } from '@/utils/stringHelpers';

interface Props {
  copyright: string | null;
  background_color: string | null;
  copyright_color: string | null;
  link_color: string | null;
  columns: Record<string, FooterLink[]>;
}

export function Content({
  copyright,
  background_color,
  copyright_color,
  link_color,
  columns,
}: Props) {
  const { organization } = useSelector((state) => state.app.organization);
  const { logo_cta_url } = useConfigOverride('header');
  const {
    footer_logo,
    facebook_url,
    twitter_url,
    instagram_url,
    youtube_url,
    tiktok_url,
    apple_store_url,
    google_play_store_url,
  } = useConfigOverride('footer');

  const socialLinks = [
    { id: SocialLinks.facebook, value: facebook_url },
    { id: SocialLinks.twitter, value: twitter_url },
    { id: SocialLinks.instagram, value: instagram_url },
    { id: SocialLinks.youtube, value: youtube_url },
    { id: SocialLinks.tiktok, value: tiktok_url },
  ].filter((item) => Boolean(item.value));

  function getSocialIcon(type: SocialLinks) {
    switch (type) {
      case SocialLinks.facebook:
        return <Social.Facebook title="Find us on Facebook" />;
      case SocialLinks.twitter:
        return <Social.Twitter title="Find us on Twitter" />;
      case SocialLinks.instagram:
        return <Social.Instagram title="Find us on Instagram" />;
      case SocialLinks.youtube:
        return <Social.Youtube title="Find us on YouTube" />;
      case SocialLinks.tiktok:
        return <Social.Tiktok title="Find us on TikTok" />;

      default:
        return null;
    }
  }

  return (
    <Container
      css={{
        backgroundColor: background_color ?? 'transparent',
        '& a': {
          color: link_color ?? 'black',
        },
      }}
    >
      <Stack
        direction="vertical"
        css={{
          gap: '$6',
          padding: '$12',
          textAlign: 'center',
        }}
      >
        <Render condition={Boolean(footer_logo)}>
          <Link passHref href={Boolean(logo_cta_url) ? logo_cta_url : ROUTES.HOMEPAGE}>
            <Logo
              src={resizeImage(footer_logo, { width: 240 })}
              css={{ height: '$12', width: '$32' }}
              height={48}
              width={128}
              alt={organization.label}
            />
          </Link>
        </Render>

        <nav>
          <Stack align="center" position="center" css={{ gap: '$5', flexWrap: 'wrap' }}>
            {Object.keys(columns).map((key) => {
              const links = columns[key] ?? [];

              return (
                <Render condition={links.length > 0} key={key}>
                  <Stack direction="vertical">
                    {links.map((link, index: number) => (
                      <Link key={index} href={link.url} passHref legacyBehavior>
                        <NavLink
                          css={{ color: link_color ?? 'black' }}
                          target={link.external ? '_blank' : '_self'}
                        >
                          {link.text}
                        </NavLink>
                      </Link>
                    ))}
                  </Stack>
                </Render>
              );
            })}
          </Stack>
        </nav>

        <Render condition={socialLinks.length > 0}>
          <Stack css={{ gap: '$5' }}>
            {socialLinks.map((item) => (
              <SocialLink
                href={item.value}
                key={item.id}
                css={{ color: link_color ?? 'black' }}
                target="_blank"
              >
                {getSocialIcon(item.id)}
              </SocialLink>
            ))}
          </Stack>
        </Render>

        <Render condition={Boolean(apple_store_url || google_play_store_url)}>
          <Stack>
            <Render condition={Boolean(apple_store_url)}>
              <SocialLink href={apple_store_url} target="_blank">
                <Image
                  src="/static/img/app-stores/apple.png"
                  alt="Apple App Store"
                  height={40}
                  width={120}
                />
              </SocialLink>
            </Render>

            <Render condition={Boolean(google_play_store_url)}>
              <SocialLink href={google_play_store_url} target="_blank">
                <Image
                  src="/static/img/app-stores/google.png"
                  alt="Google Play Store"
                  height={40}
                  width={144}
                />
              </SocialLink>
            </Render>
          </Stack>
        </Render>

        <Render condition={Boolean(copyright)}>
          <Subtext
            css={{ color: copyright_color ?? 'black' }}
            dangerouslySetInnerHTML={{
              __html: interpolateIntoString(copyright, {
                year: formatDate(null, DATE_FORMAT.YEAR),
                brandName: organization.label,
              }),
            }}
          ></Subtext>
        </Render>
      </Stack>
    </Container>
  );
}

export function Footer() {
  const router = useRouter();
  const footerEnabled = useSelector(function (state) {
    const webConfig = state.app.cmsConfig.webConfig;
    return safelyGetConfig(webConfig, 'global.footer_enabled');
  });
  const { organization } = useSelector((state) => state.app.organization);

  /**
   * Make sure that `useFooterQuery` is prefetched before
   * this component renders, otherwise it will explode!
   * @see __DANGEROUS_EMPTY_REQUEST_ORIGIN
   */
  let origin: string;
  if (typeof window !== 'undefined') {
    origin = getOrigin(window.location.host);
  } else {
    origin = getOrigin(__DANGEROUS_EMPTY_REQUEST_ORIGIN);
  }

  const { data, status } = useFooterQuery(origin, organization.id);

  /*
   * Display the footer when the following conditions pass:
   * - footer config is enabled
   * - marketing api returns valid footer JSON
   * - we are not on account pages
   */
  if (!footerEnabled || status !== 'success' || router?.pathname === ROUTES.ACCOUNT) {
    return null;
  }

  // @ts-expect-error: api response is unknown
  return <Content {...data} />;
}

const Container = styled('footer', {});

const Logo = styled('img', {
  objectFit: 'contain',
});

const NavLink = styled('a', {
  fontSize: '$text200',
  transition: 'all .2s ease',

  '&:hover': {
    transform: 'translateY(-.25rem)',
  },
});

const SocialLink = styled('a', {
  transition: 'all .2s ease',
  transform: 'translate3d(0,0,0)', // safari workaround to prevent layout shifting during hover

  '&:hover': {
    opacity: 0.6,
  },
});

const Subtext = styled('span', {
  fontSize: '$text200',
});
